<template>
  <content-viewer :title="title" @close="triggerClose()">
    <template #trigger="{ open }">
      <div ref="triggerRef" class="hidden" @click="open()"></div>
    </template>
    <template #default>
      <div class="max-h-60dvh scrollbar w-auto pt-7 pb-10 md:w-110 md:pt-0">
        <div>{{ body }}</div>
      </div>
    </template>
    <template #footer="{ close }">
      <div class="w-full py-3 px-5 flex justify-center gap-2 md:py-6 md:justify-end">
        <nd-button v-if="!!cancelText" type="tertiary" class="w-full md:w-auto" @click="triggerCancel(close)">{{ cancelText }}</nd-button>
        <nd-button type="primary" class="w-full md:w-auto" @click="triggerConfirm(close)">{{ confirmText }}</nd-button>
      </div>
    </template>
  </content-viewer>
</template>

<script setup lang="ts">
import ContentViewer from '@/components/common/content-viewer.vue'

type PropTypes = {
  title: string
  body: string
  cancelText: string
  confirmText: string
  onCancel?: () => void
  onConfirm?: () => void
  onClose?: () => void
}

const props = withDefaults(defineProps<PropTypes>(), {
  title: '',
  body: '',
  cancelText: () => useI18n().t('all_cancel'),
  confirmText: () => useI18n().t('all_confirm'),
  onConfirm: () => {},
  onCancel: () => {},
  onClose: () => {},
})

const show = defineModel('show', { default: false })

const { title, body, cancelText, confirmText } = toRefs(props)
const triggerRef = templateRef('triggerRef')

watchDebounced(
  show,
  () => {
    if (show.value) {
      showModal()
    }
  },
  { debounce: 100 }
)

function showModal() {
  const el: HTMLDivElement = triggerRef.value as any
  el.click()
}

function triggerClose(cb?: () => void) {
  cb?.()
  props.onClose?.()
  show.value = false
}

function triggerCancel(cb?: () => void) {
  cb?.()
  props.onCancel?.()
  show.value = false
}

function triggerConfirm(cb: () => void) {
  cb?.()
  props.onConfirm?.()
  show.value = false
}
</script>
